import { useEffect } from 'react';
import {auth} from '../services/firebase-config'
import { getAccountDetails,requestDataByAssociate, getMainAccount, getCompanyDetails, requestSuperData } from './dashboardHook';
import useDashboardStore from './useDashboardStore';

const UseDashboardAction = ({ children }) => {
  const {
    setCompanies,
    setEmployees,
    setAssociateAccounts,
    setFindings,
    setPopups,
    setPlans,
    setMachinesReports,
    setPeriodics,
    setSafetyReports,
    setSafetyReportsAreas,
    setSafetyReportsItems,
    setSafetyCategories,
    setSafetyTests,
    setTrainingGroups,
    setTrainingItems,
    setLogs,
    setUserPersonalData,
    setCommittees,
    setIsLoading,
    setMyCompany,
    setMyPlan,
    setMainAccount,
    setAccounts,
    setDebriefingReports,
    setLoadingMessage} = useDashboardStore();

  useEffect(() => {
    const preloadData = async () => {
      setIsLoading(true);
      setLoadingMessage('טוען פרטי חשבון');
      const userData = await getAccountDetails(auth.currentUser.uid);
      const userCompany = await getCompanyDetails(userData.associateId);
      setMyCompany(userCompany);

      const mainAccount = await getMainAccount(userData.associateId);
      setMainAccount(mainAccount);

      setLoadingMessage('טוען דוחות');
      const committees = await requestDataByAssociate(userData.associateId, 'committees');
      setLoadingMessage('טוען רשימת חברות');
      const companies = await requestDataByAssociate(userData.associateId, 'companies');
      setLoadingMessage('טוען רשימת עובדים');
      const employees = await requestDataByAssociate(userData.associateId, 'employees');
      const associateAccounts = await requestDataByAssociate(userData.associateId, 'accounts');
      setLoadingMessage('טוען ממצאי בטיחות');
      const findings = await requestDataByAssociate(userData.associateId, 'findingList');
      setLoadingMessage('טוען סקרים תקופתיים');
      const machinesReports = await requestDataByAssociate(userData.associateId, 'machines_reports');
      const sortedCommittees = machinesReports.sort((a, b) => a.reportDate - b.reportDate);
      setLoadingMessage('טוען דוחות בטיחות');
      const safetyReports = await requestDataByAssociate(userData.associateId, 'safetyReports');
      setLoadingMessage('טוען הגדרות מערכת');

      const debriefingReports = await requestDataByAssociate(userData.associateId, 'debriefing_reports');

      const periodics = await requestDataByAssociate(userData.associateId, 'periodicTracking');
      setLoadingMessage('טוען פעולות אחרונות');
      const allLogs = await requestDataByAssociate(userData.associateId, 'logs');
      const safetyReportsAreas = await requestDataByAssociate(userData.associateId, 'safetyReportsAreas');
      const safetyReportssItems = await requestDataByAssociate(userData.associateId, 'safetyReportsItems');
      const safetyTestingCategories = await requestDataByAssociate(userData.associateId, 'safetyTestingCategories');
      const trainingItems = await requestDataByAssociate(userData.associateId, 'trainingItems');
      setLoadingMessage('טוען מבדקים בחירום');
      const safetyTests = await requestDataByAssociate(userData.associateId, 'safetyTests');
      setLoadingMessage('טוען ועדות בטיחות');
      const trainingGroups = await requestDataByAssociate(userData.associateId, 'trainingGroups');
      setLoadingMessage('טוען משתמשים בחברה');
      const allAccounts = await requestDataByAssociate(userData.associateId, 'accounts');
      const allPopups = await requestSuperData('adminPopups');
      const allPlans = await requestSuperData('plans');



      const myPlanRequest = allPlans.find(x => x.id === mainAccount.planId);
      setMyPlan(myPlanRequest);


      setUserPersonalData(userData);
      setCommittees(committees);
      setCompanies(companies);
      setEmployees(employees);
      setAssociateAccounts(allAccounts);
      setFindings(findings);
      setPopups(allPopups);
      setPlans(allPlans);
      setMachinesReports(sortedCommittees);
      setPeriodics(periodics);
      setAccounts(associateAccounts);
      setSafetyReports(safetyReports);
      setSafetyReportsAreas(safetyReportsAreas);
      setSafetyReportsItems(safetyReportssItems);
      setSafetyCategories(safetyTestingCategories);
      setSafetyTests(safetyTests);
      setLogs(allLogs);
      setTrainingGroups(trainingGroups);
      setTrainingItems(trainingItems);
      setDebriefingReports(debriefingReports);
      setIsLoading(false);
    };
    preloadData();
  }, [setUserPersonalData]);
  return children;
};

export default UseDashboardAction;
