import React, {useEffect, useState} from "react";
import {ToastContainer, toast} from 'react-toastify';
import {database} from '../../services/firebase-config'
import {addDoc,collection} from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom";
import {requestSuperData} from '../../store/dashboardHook';
import emailjs from 'emailjs-com';

const Home = () => {

    
    const [fullname, setFullname] = useState('')
    const [companyName, setCompanyname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [freetext, setFreetext] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [sitePlans, setSitePlans] = useState([]);

    const sendContact = async() => {
        setIsLoading(true)
        if(fullname !== '' && companyName !== '' && phone !== '' && email !== ''){
            try {
                
                let contact = {
                    createdAt: Date.now(),
                    fullname: fullname,
                    companyName: companyName,
                    email: email,
                    phone: phone,
                    freetext: freetext
                  };
                  const docRef = await addDoc(collection(database, "contacts"), contact);

                  emailjs.send('service_faxspbm', 'template_ibpr275', {
                    fullname: fullname,
                    companyName: companyName,
                    email: email,
                    phone: phone,
                    freetext: freetext
                  }, 'ILHnLsgUpnsAV_fJT')
                    .then((response) => {
                      console.log('SUCCESS!', response.status, response.text);
                    }, (err) => {
                      console.log('FAILED...', err);
                    });

                  setFullname('')
                  setCompanyname('')
                  setPhone('')
                  setEmail('')
                  setFreetext('')
                  setIsLoading(false)
                  toast.success('הפנייה נשלחה בהצלחה')

            } catch (error) {
                setIsLoading(false)
                toast.error(error.message)
            }
        } else {
            setIsLoading(false)
            toast.error('נא למלא את כל השדות בטופס ההתקשרות')
        }
    }

    useEffect(() => {
        loadSitePlans()
    },[])

    const loadSitePlans = async() => {
        try {
            const allPlans = await requestSuperData('plans');
            const filteredPlans = allPlans.filter(x => x.displayInSite === true);
            const sortedPlans = filteredPlans.sort((a, b) => a.planCost - b.planCost);
            setSitePlans(sortedPlans);
        } catch (error) {
          console.log(error.message);
        }
    }

    return(
        <>
        <ToastContainer />
            <div className="container-fluid" style={{background:'#f8f9fa'}}>

                <div className="container">          
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <a className="navbar-brand">
                            <img src="./images/site-logo.png" style={{width:160}} />
                        </a>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item"><a className="nav-link" href="#welcome">ברוכים הבאים</a></li>
                            <li className="nav-item"><a className="nav-link" href="#features">תכונות המערכת</a></li>
                            <li className="nav-item"><a className="nav-link" href="#saas">מערכת SaaS</a></li>
                            <li className="nav-item"><a className="nav-link" href="#plans">מסלולים</a></li>
                            <li className="nav-item"><a className="nav-link" href="#app">האפליקצייה שלנו</a></li>
                            <li className="nav-item"><a className="nav-link" href="#contact">צרו קשר</a></li>
                            <li className="nav-item btnwrap">
                                <Link className="nav-link" to='/login'>כניסה למערכת</Link></li>
                        </ul>
                        </div>
                    </div>
                    </nav>
                </div>
            </div>

            <div className="container" id="welcome">
                <div className="row">
                    <div className="col-lg-4" style={{paddingTop:120}}>
                    <h2 className="site-subtitle">ברוכים הבאים למערכת ״לממונה״</h2>
                        <p className="site-content">
                        המערכת "לממונה" היא כלי ניהולי מתקדם לניהול בטיחות ובקרה על אתרים ומפעלים. היא מיועדת למנהלים, ממוני בטיחות וצוותי בקרה, ומאפשרת מעקב אחר סיכונים, ניהול הדרכות, תיעוד אירועים וביצוע ביקורות תקופתיות. המערכת מספקת תמונת מצב כוללת על מצב הבטיחות בכל אתר, ומאפשרת זיהוי מוקדם של בעיות, טיפול מיידי ועמידה מלאה בתקנות הבטיחות המחמירות ביותר, ובכך תורמת לשמירה על סביבת עבודה בטוחה ויעילה.
                        </p>
                        <Link className="btn btn-warning btn-lg site-btn" to='/login'>להרשמה למערכת</Link>
                    </div>
                    <div className="col-lg-8">
                        <img src="./images/hero.png" />
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 slogan-container">
                        <h2 className="yellow-title">כל מה שצריך במערכת אחת!</h2>
                        <h2 className="white-sub">
                        מתאימה לממוני בטיחות ולחברות<br/>
                        שאוהבות לנהל בצורה האיכותית והמקצועית ביותר!
                        </h2>
                    </div>
                </div>
            </div>

            <div className="container-fluid" id="features" style={{background:'#F7F7F7',paddingTop:100, paddingBottom:100}}>
                <div className="container">
                <div className="row">
                    <div className="col-lg-12" style={{textAlign:'center'}}>
                        <h2 className="site-subtitle">תכונות המערכת</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/3.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">דוחות בטיחות</h3>
                        <p className="feature-content">דוחות בטיחות הם מסמכים שמתעדים את מצב הבטיחות בסביבת העבודה, כולל ממצאי בדיקות, אירועים חריגים, סיכונים שהתגלו והצעדים שננקטו לתיקונם. הדוחות כוללים גם המלצות לשיפור ומתן פתרונות למניעת תקלות עתידיות. הם כלי חשוב לניטור ושיפור מתמיד של רמת הבטיחות בארגון.</p>
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/2.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">מעקבים תקופתיים</h3>
                        <p className="feature-content">מעקבים תקופתיים לציוד הם בדיקות סדירות שמבוצעות לצורך הבטחת תקינות ובטיחות הציוד בשימוש. הם כוללים בדיקת תפקוד, תחזוקה והחלפת חלקים לפי הצורך, ומטרתם למנוע תקלות או תאונות עבודה. מעקבים אלו חיוניים לשמירה על יעילות העבודה ובטיחות העובדים.</p>
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/1.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">הדרכות</h3>
                        <p className="feature-content">הדרכות בטיחות הן מפגשים חינוכיים שמטרתם להקנות לעובדים ידע וכלים לשמירה על בטיחותם בסביבת העבודה. הן כוללות הסברים על נהלי עבודה בטוחים, שימוש נכון בציוד מגן, זיהוי סיכונים ודרכי התגובה במקרי חירום. הדרכות אלו חיוניות למניעת תאונות ולהגברת המודעות לבטיחות.</p>
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/6.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">ועדות בטיחות</h3>
                        <p className="feature-content">ועדת בטיחות היא גוף ארגוני שמטרתו להבטיח סביבה בטוחה לעובדים. היא כוללת נציגים מהנהלה והעובדים, ונפגשת באופן קבוע לדון בסיכונים, להציע פתרונות ולוודא עמידה בתקנות. הוועדה אחראית לקדם מודעות ולבצע מעקב אחר יישום נהלי בטיחות.</p>
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/5.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">תרגילי בטיחות</h3>
                        <p className="feature-content">תרגילי בטיחות הם פעילויות שמדמות מצבי חירום במטרה להכין את העובדים להגיב בצורה נכונה ומהירה. תרגילים אלו כוללים פינוי מבנים, כיבוי אש וטיפול בתקלות שונות, ומטרתם לשפר את הידע והיכולות של העובדים, להבטיח מוכנות ולמנוע פגיעות.</p>
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center'}}>
                        <img src="./images/4.png" style={{width:100, marginTop:50}} />
                        <h3 className="feature-title">ניהול חברות ועובדים</h3>
                        <p className="feature-content">דף ניהול חברות ועובדים במערכת מאפשר למנהלים לנהל בקלות את פרטי החברות והעובדים. ניתן להוסיף, לערוך ולעקוב אחר פרטי החברות, ולנהל את המידע האישי, התפקידים וההכשרות של העובדים בצורה מרוכזת ויעילה, לשיפור התפקוד הארגוני.</p>
                    </div>
                </div>
                </div>
            </div>


            <div className="container" id="saas" style={{paddingTop:100}}>
                <div className="row">
                    <div className="col-lg-12" style={{textAlign:'center'}}>
                        <h2 className="site-subtitle">ממשק SaaS אינטואטיבי</h2>
                        <p className="site-content">ממשק ה-SaaS האינטואיטיבי של אפליקציית ניהול הבטיחות מספק לארגונים פלטפורמה חכמה, נגישה ומאובטחת לניהול כל היבטי הבטיחות במקום העבודה. בזכות הטכנולוגיה מבוססת ענן, ניתן לגשת למערכת מכל מכשיר עם חיבור לאינטרנט, ללא צורך בהתקנות מורכבות או תחזוקה טכנית מצד הלקוח. הממשק עוצב באופן ידידותי למשתמש, כך שגם משתמשים ללא ידע טכני מתקדם יוכלו להשתמש בו בקלות ובמהירות.
האפליקציה מציעה מגוון רחב של כלים מתקדמים לניהול בטיחות, כולל מעקב אחר משימות, תיעוד אירועים ותקריות, ניהול ציוד בטיחות, וניהול הדרכות עובדים. כל המידע מוצג בממשק פשוט וברור, המאפשר למשתמשים לגשת במהירות לכל נתון נדרש ולבצע פעולות בקלות.
האינטואיטיביות של הממשק מאפשרת לארגונים לייעל את תהליכי הבטיחות שלהם באופן משמעותי, תוך שמירה על תאימות לתקנות ולסטנדרטים המחמירים ביותר. הממשק מתעדכן באופן אוטומטי עם שיפורים ותוספות חדשות, מה שמבטיח תמיד עבודה עם הגרסה המתקדמת ביותר של האפליקציה. עם ממשק ה-SaaS האינטואיטיבי, ניהול הבטיחות הופך לפשוט, יעיל ונגיש מכל מקום ובכל זמן.</p>
                    </div>
                    <div className="col-lg-12">
                        <img src="./images/saas.png" className="site-mobile" />
                    </div>
                </div>
            </div>


            <div className="container" id="plans" style={{paddingTop:100}}>
                <div className="row">
                    <div className="col-lg-12" style={{textAlign:'center'}}>
                        <h2 className="site-subtitle">המסלולים שלנו</h2>
                        <p className="site-content">המערכת מציעה ארבעה מסלולים לבחירה: בסיסי, מורחב, פרימיום ומסלול בהתאמה אישית. כל מסלול מותאם לצרכים שונים, ומאפשר ניהול מספר משתנה של חברות ואתרים, עם גישה להדרכות אדמין. כך, כל ארגון יכול לבחור את המסלול החודשי שמתאים לו ולמקסם את השימוש במערכת.</p>
                    </div>
                </div>
                <div className="row" style={{marginTop:30}}>


                    {
                        sitePlans.length > 0 && <>
                            {
                                sitePlans.map(plan => (<>
                                <div className="col-lg-3">
                                    <div className="plan-container">
                                        <h3 className="feature-title">{plan.planName}</h3><br/>
                                        <p className="listp">{plan.users} משתמשים</p>
                                        <p className="listp">{plan.companies} חברות</p>
                                        <p className="listp">{plan.sites} אתרים</p>
                                        <p className="listp">{plan.employees} עובדים</p>
                                        <p className="listp">ללא הגבלת אביזרים</p>
                                        <p className="listp">ייצוא, ייבוא, SMSים, התראות ליליות - <b>
                                            {
                                                plan.actions ? 'כלול' : 'לא כלול'
                                            }
                                            </b></p>
                                        <br/>
                                        <h3 className="feature-price">₪{plan.planCost}</h3>
                                        <p style={{padding:0, margin:0}}>לחודש / המחיר אינו כולל מע״מ</p>
                                    </div>
                                </div>
                                </>))

                                
                            }
                        </>
                    }

<div className="col-lg-3">
                                    <div className="plan-container">
                                    <h3 className="feature-title">מותאם אישית</h3>


                                    <p className="listp">אתרים - מותאם אישית</p>
                                    <p className="listp">עובדים - מותאם אישית</p>
                                    <p className="listp">חברות - מותאם אישית</p>
                                    <p className="listp">יוזרים - מותאם אישית</p>
                                    <p className="listp">אפשרות ייבוא וייצוא מסמכים פעיל</p>

                    <p>לא מצאת את החבילה המושלמת עבורך? ניתן להתאים חבילה על פי הצורך :-) לפרטים נוספים פנה אלינו בטלפון: 054-6999688 או 08-6999299</p>
                    <br /><br/>
                  </div>
                </div>



                    {/* <div className="col-lg-3">
                        <div className="plan-container">
                        <h3 className="feature-title">בסיסי</h3>
                        <br/>
                        
                        
                        
                        
                        
                        
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="plan-container">
                        <h3 className="feature-title">מורחב</h3>
                        <br/>
                        <p className="listp">12 משתמשים</p>
                        <p className="listp">4 חברות</p>
                        <p className="listp">12 אתרים</p>
                        <p className="listp">400 עובדים</p>
                        <p className="listp">ללא הגבלת אביזרים</p>
                        <p className="listp">50 דוחות</p>
                        <br/>
                        <h3 className="feature-price">₪449</h3>
                        <p style={{padding:0, margin:0}}>לחודש</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="plan-container">
                        <h3 className="feature-title">פרימיום</h3>
                        <br/>
                        <p className="listp">24 משתמשים</p>
                        <p className="listp">6 חברות</p>
                        <p className="listp">24 אתרים</p>
                        <p className="listp">800 עובדים</p>
                        <p className="listp">ללא הגבלת אביזרים</p>
                        <p className="listp">100 דוחות</p>
                        <br/>
                        <h3 className="feature-price">₪589</h3>
                        <p style={{padding:0, margin:0}}>לחודש</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="plan-container">
                        <h3 className="feature-title">מותאם אישית</h3>
                        <br/>
                        <p className="listp">
                            ניתן להתאים את מספר המשתמשים, החברות, אתרים, עובדים בחברה והדוחות בהתאמה אישית. לקבלת הצעת מחיר אנא פנה באמצעות טופס ההתקשרות בתחתית העמוד
                        </p>
                        <br/>
                        <a href="#contact" className="btn btn-warning btn-lg site-btn">צור קשר</a>
                        </div>
                    </div> */}


                </div>
            </div>


            <div className="container" id="app" style={{paddingTop:100}}>
                <div className="row">
                    <div className="col-lg-5 paddingTopBottom">
                        <h2 className="site-subtitle">אפליקציית לממונה בטיחות</h2>
                        <p className="site-content">
                        אפליקציית ניהול הבטיחות היא כלי חיוני לארגונים לניהול מקיף ויעיל של כל היבטי הבטיחות במקום העבודה. האפליקציה מספקת כלים לניהול סיכונים, מעקב אחר הדרכות בטיחות, תיעוד אירועים ותקריות, וניהול ציוד בטיחות עם ביצוע ביקורות תקופתיות. בנוסף, היא מאפשרת מעקב אחר תאימות לתקנות וסטנדרטים, ומסייעת במניעת תאונות ושמירה על סביבת עבודה בטוחה. האפליקציה זמינה בכל עת ומכל מקום, ומבטיחה גישה נוחה למידע חשוב לצוותי ניהול הבטיחות.
                        </p>
                        <div className="row" style={{marginTop:20}}>
                            <div className="col-lg-6">
                                <a href="#" target="_blank">
                                    <img src="./images/android.png" />
                                </a>
                            </div>
                            <div className="col-lg-6">
                                <a href="#" target="_blank">
                                    <img src="./images/apple.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img src="./images/mobile-apps.png" className="site-mobile" />
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img src="./images/footer-visual.png" className="site-mobile" />
                    </div>
                </div>
            </div>


            <div id="contact" className="container-fluid footer-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <img src="./images/foo-logo.png" style={{width:120}} /><br/><br/>
                            <p className="address">
                            באר שבע – בוסתנאי 3 (הנהלה ראשית)<br/>
                            08-6999299 | 054-699-9688<br/>
                            <a style={{color:'#fff'}} href='mailto:tomer@t-safety.co.il'>tomer@t-safety.co.il</a>
                            </p>
                        </div>
                         <div className="col-lg-3">
                            <a className="foolinks" href="#welcome">ברוכים הבאים</a>
                            <a className="foolinks" href="#features">תכונות המערכת</a>
                            <a className="foolinks" href="#saas">מערכת SaaS</a>
                            <a className="foolinks" href="#plans">מסלולים</a>
                            <a className="foolinks" href="#app">האפליקצייה שלנו</a>
                            <a className="foolinks" href="#contact">צרו קשר</a>
                            <Link className="foolinks" to='/login'>כניסה למערכת</Link>
                         </div>
                        <div className="col-lg-6">
                            <p className="form-text">לפרטים נוספים ולכל שאלה הנכם מוזמנים לפנות אלינו באמצעות טופס ההתקשרות</p>
                            <div className="row" style={{marginTop:20}}>
                                <div className="col-lg-6">
                                    <input required value={fullname} onChange={(e) => {setFullname(e.target.value)}} className="form-control" placeholder="שם מלא" />
                                </div>
                                <div className="col-lg-6">
                                    <input required value={companyName} onChange={(e) => {setCompanyname(e.target.value)}} className="form-control" placeholder="שם החברה" />
                                </div>
                                <div className="col-lg-6" style={{marginTop:5}}>
                                    <input required value={phone} onChange={(e) => {setPhone(e.target.value)}} className="form-control" placeholder="טלפון נייד" />
                                </div>
                                <div className="col-lg-6" style={{marginTop:5}}>
                                    <input required value={email} onChange={(e) => {setEmail(e.target.value)}} className="form-control" placeholder="דואר אלקטרוני" />
                                </div>
                                <div className="col-lg-6" style={{marginTop:5}}>
                                    <input required value={freetext} onChange={(e) => {setFreetext(e.target.value)}} className="form-control" placeholder="גוף הפנייה" />
                                </div>
                                <div className="col-lg-6" style={{marginTop:5}}>
                                    {
                                        isLoading ? (

                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>

                                        ) : (<button onClick={sendContact} className="btn btn-warning site-btn2">שלח פנייה</button>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Home;