import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { BsFillFilePdfFill } from "react-icons/bs";
import { GiFireExtinguisher, GiTowTruck, GiCargoCrane } from "react-icons/gi";
import { IoLocationOutline,IoPhonePortraitOutline,IoMailOutline,IoPeopleOutline,IoSettingsSharp,IoBusOutline,IoBusinessOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos, MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";


const MachineReportItem = (props) => {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const machine = props.machines ? props.machines.filter((x) => x.empId === props.machine.associateMachineId) : "";
  const company = props.companiesArr ? props.companiesArr.filter((x) => x.id === props.machine.associateCompanyId): "";
  const todayMax = new Date().toISOString().split('T')[0];

  const today = new Date();
  const [reportType, setreportType] = useState(props.machine.reportType);
  const [checkType, setCheckType] = useState(props.machine.checkType);
  const [reportComments, setReportComments] = useState(props.machine.reportComments);
  const [reportFile, setReportFile] = useState("");
  const [reportDate, setReportDate] = useState(props.machine.reportDate);
  const [nextReportDate, setNextReportDate] = useState(props.machine.nextReportDate);
  const [selectedCategory, setSelectedCategory] = useState(props.machine.department);


  let textColor = "#000000";
  let numberOfDays = 0;

  const calculateDays = () => {
    var startDate = moment(props.machine.nextReportDate).format("MM/DD/YYYY");
    var currenDate = moment(new Date()).format("MM/DD/YYYY");
    numberOfDays = datediff(parseDate(currenDate), parseDate(startDate));
  };

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  function parseDate(str) {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  calculateDays();

  let priorityColor = "#ffcc00";
  if (numberOfDays > 30) {
    priorityColor = "#67eb34";
  }
  if (numberOfDays < 30) {
    priorityColor = "#ffcc00";
  }
  if (numberOfDays < 1) {
    priorityColor = "#EB6079";
  }

  if (numberOfDays <= 0) {
    textColor = "#EB6079";
  } else if (numberOfDays > 0 && numberOfDays < 30) {
    textColor = "#ff9900";
  } else {
    textColor = "#000000";
  }





  const handleDelete = () => {
    setShowConfirmation(true);
  };
  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    onDelete();
  };
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };
  const onDelete = async () => {
    props.removeReport(props.machine);
    setIsEditView(false);
  };



  const t = machine[0]?.machineManifacture + " " + machine[0]?.machineModel + " " + machine[0]?.machineLicenseNumber + " | " + machine[0]?.machineSerialNumber


  useEffect(() => {
    if (reportType !== "") {
      const monthsToAdd = parseInt(props.periodicList?.find(x => x.trackingName === reportType)?.periodicTime, 10) || 0;
  
      const origDate = new Date(reportDate);
  
      let targetYear = origDate.getFullYear();
      let targetMonth = origDate.getMonth() + monthsToAdd;
      let targetDay = origDate.getDate();
  
      while (targetMonth > 11) {
        targetYear++;
        targetMonth -= 12;
      }
  
      const newDate = new Date(targetYear, targetMonth, targetDay);
      setNextReportDate(newDate);
    }
  }, [reportDate, reportType, props.periodicList]);



  return (
    <>
      <div
        className="row"
        style={{
          marginBottom: 10,
          padding: 5,
          borderRadius: 12,
          background: "#F8FCFC",
        }}
      >
        {isEditView ? 
        (
          <>
            <div className="row">

            <div className="col-lg-3">
              <label className="form-label">מחלקה</label>
              <select className="form-control" onChange={(e) => {setSelectedCategory(e.target.value)}}>
              <option value=''>בחר מחלקה מהרשימה...</option>
              {
                props?.departmentList?.map((item,index) => (
                  <option selected={selectedCategory === item.departmentName ? true : false} value={item.departmentName} key={index}>{item.departmentName}</option>
                ))
              }
              </select>
              </div>



              <div className="col-lg-3">
              <label className="form-label">סוג התסקיר</label>
              <select className="form-control" onChange={(e) => {setreportType(e.target.value)}}>
              {
                props?.periodicList?.map(item => (
                  <option selected={reportType === item.trackingName ? true : false} value={item.trackingName} key={item.id}>{item.trackingName}</option>
                ))
              }
              </select>
              </div>
              <div className="col-lg-3">
              <label className="form-label">סוג הבדיקה</label>
                  <select className="form-control" onChange={(e) => {setCheckType(e.target.value)}}>
                    <option selected={checkType === 'בדיקה תקופתית' ? true : false} value="בדיקה תקופתית">בדיקה תקופתית</option>
                    <option selected={checkType === 'בדיקה ראשונה' ? true : false} value="בדיקה ראשונה">בדיקה ראשונה</option>
                    <option selected={checkType === 'בדיקה מיוחדת' ? true : false} value="בדיקה מיוחדת">בדיקה מיוחדת</option>
                  </select>
              </div>

              <div className="col-lg-3">
              <label className="form-label">מכונה / אביזר</label>
                  <input disabled
                    type="text"
                    className="form-control"
                    value={t} />
              </div>



              <div className="col-lg-4">
              <label className="form-label">תאריך הסקירה</label>
                  <input
                    max={todayMax}
                    type="date"
                    className="form-control"
                    value={moment(reportDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setReportDate(e.target.value);
                    }}
                  />
              </div>
              <div className="col-lg-4">
              <label className="form-label">תאריך הסקירה הבאה</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(nextReportDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setNextReportDate(e.target.value);
                    }}
                  />
              </div>
              <div className="col-lg-4">
              <label className="form-label">העלאת קובץ חדש</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setReportFile(e.target.files[0]);}}
              />
              </div>
              <div className="col-lg-12">
              <label className="form-label">תיאור הסקירה</label>
              <textarea
                className="form-control"
                value={reportComments}
                onChange={(e) => {setReportComments(e.target.value)}}></textarea>
              </div>


            </div>
            <div className="row" style={{marginTop:20, marginBottom:20}}>
              <div className="col-lg-1">
                <button onClick={() => setIsEditView(!isEditView)} className="btn btn-light"><MdOutlineArrowForwardIos color="#A3BAC3" size={30} /></button>
              </div>
              <div className="col-lg-1">
                <button onClick={handleDelete} className="btn btn-danger"><MdDelete color="#ffffff" size={30} /></button>
              </div>
              <div className="col-lg-6">
              {showConfirmation && (
                <div style={{ flexDirection: "row", marginTop: 10 }}>
                  <p>
                    אישור מחיקה{" "}
                    <button className="btn btn-danger" onClick={handleConfirmDelete}>מחיקה</button>{" "}
                    <button className="btn btn-success" onClick={handleCancelDelete}>ביטול</button>
                  </p>
                </div>
              )}
              </div>
              <div className="col-lg-2">
              </div>
              <div className="col-lg-2">
                <button onClick={() => {
                  props.updateReport(selectedCategory,reportType,checkType,reportComments,reportFile,reportDate,nextReportDate,props.machine.id);
                  setIsEditView(false)
                }} className="btn btn-success">שמירת שינויים</button>
              </div>
            </div>
          </>
        ) 
        : 
        (
          <>

            <div className="col-lg-6" style={{ padding: 15 }}>
              <h6>
                {machine[0]?.machineManifacture} {machine[0]?.machineModel}{" "}
                {machine[0]?.machineLicenseNumber} |{" "}
                {machine[0]?.machineSerialNumber}
              </h6>
              <p>
                  סוג התסקיר: <b>{props.machine.reportType}</b> | סוג הבדיקה: <b>{props.machine.checkType}</b> | מחלקה: <b>{props.machine.department}</b>
              </p>
              <p>
                <b>{company[0].companyName}</b> תאריך הבדיקה:{" "}
                <b>{moment(props.machine.reportDate).format("DD/MM/YYYY")}</b>
              </p>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center", paddingTop: 20 }}>



              <p>קבצים ({props.machine?.reportFiles ? props.machine.reportFiles.length : 0})</p>
              {
                props.machine?.reportFiles && props.machine?.reportFiles.length > 0 && (<>
                {
                  props.machine.reportFiles.map((file) => (
                  <a href={file} target="_blank" rel="noreferrer"><BsFillFilePdfFill color="#EB6079" size={30} /></a>))
                }
                </>)
              }
            </div>

            <div
              className="col-lg-2"
              style={{ textAlign: "center", paddingTop: 20 }}>
              <p>הבדיקה הבאה</p>
              <p className="status">
                {moment(props.machine.nextReportDate).format("DD/MM/YYYY")}
              </p>
              <p style={{ color: textColor }}>{numberOfDays} ימים</p>
            </div>
            <div
              className="col-lg-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <button
                style={{ marginRight: 12 }}
                onClick={() => setIsEditView(!isEditView)}
                className="btn btn-light"><IoSettingsSharp color="#A3BAC3" size={30} /></button>
            </div>
            <div className="col-lg-1">
              <div
                style={{
                  borderTopLeftRadius: 12,
                  borderBottomLeftRadius: 12,
                  width: "30%",
                  float: "left",
                  height: "100%",
                  background: priorityColor,
                }}
              ></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MachineReportItem;
