import React, { useState, useEffect } from "react";

const AddMachine = (props) => {
  const [machineManifacture, setMachineManifacture] = useState("");
  const [machineYear, setMachineYear] = useState("");
  const [machineSerialNumber, setMachineSerialNumber] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machineType, setMachineType] = useState("");
  const [department, setDepartment] = useState("");
  const [machineComments, setMachineComments] = useState("");
  const [machineProject, setMachineProject] = useState("");
  const [machineLicenseNumber, setMachineLicenseNumber] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (department) {
      const filtered = props.departments.filter((dept) =>
        dept.departmentName.toLowerCase().includes(department.toLowerCase())
      );
      setFilteredDepartments(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredDepartments([]);
      setShowDropdown(false);
    }
  }, [department, props.departments]);

  const handleSelectDepartment = (deptName) => {
    setDepartment(deptName);
    setShowDropdown(false);
  };

  const [projects,setProjects] = useState(props.company.projects.length > 0 ? props.company.projects : []);

  return (
    <>
      <div className="add-machine-box">
        <div className="row">


        <div className="col-lg-3 position-relative">
            <label className="form-label">מחלקה</label>
            <input
              type="text"
              className="form-control"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                setTimeout(() => setShowDropdown(false), 100);
              }}
            />
            {showDropdown && (
              <ul className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1000, textAlign:'right' }}>
                {filteredDepartments.map((dept) => (
                  <li
                    key={dept.empId}
                    className="dropdown-item"
                    onClick={() => handleSelectDepartment(dept.departmentName)}
                  >
                    {dept.departmentName}
                  </li>
                ))}
              </ul>
            )}
          </div>


          <div className="col-lg-2">
            <label className="form-label">שיוך לאתר</label>
            <select className="form-control" value={machineProject} onChange={(e) => {setMachineProject(e.target.value)}}>
              <option value=''>נא לבחור מהרשימה</option>
              {
                projects.map(item => (
                  <option value={item.projectName} key={item.id}>{item.projectName}</option>
                ))
              }
            </select>
          </div>
          


          <div className="col-lg-3">
            <label className="form-label">סוג אביזר</label>
            <select className="form-control" onChange={(e) => {setMachineType(e.target.value)}}>
              <option value=''>נא לבחור מהרשימה</option>
              {
                props?.periodicList?.map(item => (
                  <option value={item.trackingName} key={item.id}>{item.trackingName}</option>
                ))
              }
            </select>
          </div>

          <div className="col-lg-2">
            <label className="form-label">יצרן</label>
            <input
              type="text"
              className="form-control"
              value={machineManifacture}
              onChange={(e) => {
                setMachineManifacture(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-2">
            <label className="form-label">דגם / מודל</label>
            <input
              type="text"
              className="form-control"
              value={machineModel}
              onChange={(e) => {
                setMachineModel(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-3">
            <label className="form-label">שנת ייצור</label>
            <input
              type="text"
              className="form-control"
              value={machineYear}
              onChange={(e) => {
                setMachineYear(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-3">
            <label className="form-label">מספר סידורי</label>
            <input
              type="text"
              className="form-control"
              value={machineSerialNumber}
              onChange={(e) => {
                setMachineSerialNumber(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-3">
            <label className="form-label">מספר רישוי</label>
            <input
              type="text"
              className="form-control"
              value={machineLicenseNumber}
              onChange={(e) => {
                setMachineLicenseNumber(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-3">
            <label className="form-label">תיאור האביזר</label>
            <input
              type="text"
              className="form-control"
              value={machineComments}
              onChange={(e) => {
                setMachineComments(e.target.value);
              }}
            />
          </div>


          <div className="col-lg-2" style={{ paddingTop: 30 }}>
            <button onClick={() => {props.addNewMachine(
                machineManifacture,
                machineYear,
                machineSerialNumber,
                machineModel,
                machineType,
                machineComments,
                machineLicenseNumber,
                department,
                machineProject
            )}} className="btn btn-success">הוספת אביזר</button>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default AddMachine;
