import React, { useEffect, useState } from "react";
import { auth } from "./services/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { getAccountDetails } from './services/dbHelper';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopBarnav from "./components/frame/TopBarnav";
import SideBarnav from "./components/frame/SideBarnav";
import MainContainer from "./components/frame/MainContainer";
import Committees from "./pages/committees/Comittees";
import Companies from "./pages/companies/Companies";
import Dashboard from "./pages/dashboard/Dashboard";
import Machines from "./pages/machines/Machines";
import Projects from "./pages/projects/Projects";
import Safety from "./pages/saftey-reports/Safety";
import Settings from "./pages/settings/Settings";
import Trainings from "./pages/trainings/Trainings";
import Users from "./pages/users/Users";
import Login from './pages/authentication/Login';
import CompanyEdit from "./pages/companies/CompanyEdit";
import SafetyReportsAreas from './pages/settings/SafetyReportsAreas';
import SafetyReportsItems from './pages/settings/SafetyReportsItems';
import TrainingsCategories from './pages/settings/TrainingsCategories';
import SafetyReports from "./pages/saftey-reports/SafetyReports";
import FindingList from "./pages/saftey-reports/FindingList";
import AddSafetyReport from "./pages/saftey-reports/AddSafetyReport";
import TrainingsItem from "./pages/trainings/TrainingsItem";
import TrainingsGroups from "./pages/trainings/TrainingsGroups";
import PeriodicFollowup from "./pages/settings/PeriodicFollowup";
import SafetyTesting from "./pages/settings/SafetyTesting";
import Profile from "./pages/authentication/Profile";
import Notifications from './pages/settings/Notifications';
import Logs from './pages/settings/Logs';
import AssestsCategories from "./pages/settings/AssestsCategories";
import MyCompany from "./pages/settings/MyCompany";
import SuperAdmin from "./pages/superadmin";
import AccountInfo from "./pages/superadmin/AccountInfo";
import ReportDetails from "./pages/saftey-reports/ReportDetails";
import Popups from "./pages/superadmin/Popups";
import Plans from "./pages/superadmin/Plans";
import PaySuccess from "./pages/settings/PaySuccess";
import PayError from "./pages/settings/PayError";
import Planlist from "./pages/settings/Planlist";
import Home from './pages/site/Home';
import Leads from "./pages/superadmin/Leads";
import Debriefing from "./pages/debriefing/Debriefing";
import DebriefingEdit from "./pages/debriefing/DebriefingEdit";

const App = () => {
  const [user, setUser] = useState(null);

  const loadUserAccount = async() => {
    if(user){
      const account = await getAccountDetails(user.uid);
      localStorage.setItem("profile", JSON.stringify(account));
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        loadUserAccount();
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <Router>
      {user ? (
        <>
          <div className="custom-container">
            <TopBarnav />
            <div className="row">
              <SideBarnav />
              <MainContainer>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/safety" element={<Safety />} />
                  <Route path="/machines" element={<Machines />} />
                  <Route path="/trainings" element={<Trainings />} />
                  <Route path="/committees" element={<Committees />} />
                  <Route path="/members" element={<Users />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/company-edit/:id/:view" element={<CompanyEdit />}/>
                  <Route path="/safety-reports-item" element={<SafetyReportsItems />} />
                  <Route path="/safety-reports-areas" element={<SafetyReportsAreas />} />
                  <Route path="/trainings-categories" element={<TrainingsCategories />} />
                  <Route path="/trainings-groups" element={<TrainingsGroups />} />
                  <Route path="/trainings-items" element={<TrainingsItem />} />
                  <Route path="/periodic-followup" element={<PeriodicFollowup />} />        
                  <Route path="/safety-reports" element={<SafetyReports />} />
                  <Route path="/finding-list" element={<FindingList />} />
                  <Route path="/safety-testing" element={<SafetyTesting />} />
                  <Route path="/assets-categories" element={<AssestsCategories />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/logs" element={<Logs />} />
                  <Route path="/add-safety-report/:id/:siteid" element={<AddSafetyReport />} />
                  <Route path="/view-report/:id" element={<ReportDetails />} />
                  <Route path="/account-details/:associateId" element={<AccountInfo />} />
                  <Route path="/mycompany" element={<MyCompany />} />
                  <Route path="/superadmin" element={<SuperAdmin />} />
                  <Route path="/popups" element={<Popups />} />
                  <Route path="/plans" element={<Plans />} />
                  <Route path="/paysuccess/:id" element={<PaySuccess />} />
                  <Route path="/payerror" element={<PayError />} />
                  <Route path="/planslist" element={<Planlist />} />
                  <Route path="/leads" element={<Leads />} />
                  <Route path="/debriefing" element={<Debriefing />} />
                  <Route path="/debriefing-edit/:id" element={<DebriefingEdit />} />
                </Routes>
              </MainContainer>
            </div>
          </div>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

export default App;
