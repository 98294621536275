import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";

const DebriefingItemExt = (props) => {

  return (
    <>
    <div className="col-lg-4">
        <h6>{props.report.debriefType} | שם העובד: {props.report.injuredEmployee.firstName} {props.report.injuredEmployee.lastName}</h6>
        <p><b>תאריך התאונה: </b>{moment(props.report.reportDate).format("DD/MM/YYYY")} בשעה: {props.report.reportHour}, <b>איבר שנפגע: </b>{props.report.injuredLimb}</p>
        <p><b>קבצים מצורפים:</b> {props.report.reportFiles.length}</p>
        

        <p><b>עדים לאירוע:</b> 
            {
                props.report.witnesses.length > 0 ? (<>
                {
                    props.report.witnesses.map((item) => (
                        <>{item.firstName}{" "}{item.lastName}, </>
                    ))
                }
                </>) : (<>
                אין
                </>)
            }
        </p>

        {
          props.report.extraWitnesses && (<p><b>עדים נוספים: </b>{props.report.extraWitnesses}</p>)
        }

        <p><b>רשימת תפוצה:</b> 
            {
                props.report.mailingList.length > 0 ? (<>
                {
                    props.report.mailingList.map((item) => (
                        <>{item.firstName}{" "}{item.lastName}, </>
                    ))
                }
                </>) : (<>
                אין
                </>)
            }
        </p>

    </div>
    <div className="col-lg-2">
        <p>
            <b>תיאור המקרה</b><br/>
            {props.report.description}
        </p>
    </div>
    <div className="col-lg-2">

    </div>
    <div className="col-lg-2">
        {
            props.report.isReported === 'דווח' ? (<>
            <FaCheckCircle color="#00BD9D" size={40} />
            <p>התחקיר דווח למשרד העבודה <a href={props.report.linkToReport} target="_blank">לינק</a></p>
            </>) : (<>
            <MdOutlineRadioButtonUnchecked color="#acd4da" size={40} />
            <p>התחקיר לא דווח למשרד העבודה</p>
            </>)
        }
    </div>
    <div className="col-lg-2">
    {
            !props.report.isActive ? (<>
            <FaCheckCircle color="#00BD9D" size={40} />
            <p>התחקיר נסגר</p>
            </>) : (<>
            <MdOutlineRadioButtonUnchecked color="#acd4da" size={40} />
            <p>התחקיר פעיל</p>
            </>)
        }
    </div>
    </>
  );
};

export default DebriefingItemExt;
