import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import useDashboardStore from '../../store/useDashboardStore';
import { PiUsersThree } from "react-icons/pi";
import { BsWindowStack } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { MdCreditCard,MdCrisisAlert } from "react-icons/md";
import {
    AiOutlineTeam,
    AiOutlineAppstore,
    AiOutlineFileText,
    AiOutlineCar,
    AiOutlineTrophy,
    AiOutlineContacts,
    AiOutlineControl,
    AiOutlineSafety,
    
  } from "react-icons/ai";
  import { FaHelmetSafety } from "react-icons/fa6";

const SideBarnav = () => {
  
  const [selectedPage, setSelectedPage] = useState("ראשי");
  const {selectedCompany,selectedSite,userPersonalData,mainAccount} = useDashboardStore();
  const currentDate = new Date().getTime(); // Current time in milliseconds
  const isAccountActive = mainAccount.expireDate > currentDate;

  const home = {icon: <AiOutlineAppstore size={24} />, title: 'ראשי', route: "/dashboard", isDisplay: userPersonalData.role === 'user' ? true : true}
  const settings = {icon: <AiOutlineControl size={24} />, title: 'הגדרות מערכת', route: "/settings", isDisplay: userPersonalData.role === 'user' ? false : true}
  const companies = {icon: <AiOutlineContacts size={24} />, title: 'ניהול חברות', route: "/companies", isDisplay: userPersonalData.role === 'user' ? false : true}
  const users = {icon: <AiOutlineTeam size={24} />, title: 'ניהול משתמשים', route: "/members", isDisplay: userPersonalData.role === 'user' ? false : true}
  const superAdmin = {icon: <PiUsersThree size={24} />, title: 'ארגונים', route: "/superadmin", isDisplay: userPersonalData.role === 'superadmin' ? true : false}
  const popups = {icon: <BsWindowStack size={24} />, title: 'ניהול פופאפים', route: "/popups", isDisplay: userPersonalData.role === 'superadmin' ? true : false}
  const plans = {icon: <MdAttachMoney size={24} />, title: 'ניהול מסלולים', route: "/plans", isDisplay: userPersonalData.role === 'superadmin' ? true : false}
  const leads = {icon: <FaRegEnvelope size={24} />, title: 'פניות מהאתר', route: "/leads", isDisplay: userPersonalData.role === 'superadmin' ? true : false}

    const pages = [
        {icon: <AiOutlineFileText size={24} />, title: 'דוחות בטיחות', route: "/safety", isDisplay: userPersonalData.role === 'user' ? true : true},
        {icon: <AiOutlineCar size={24} />, title: 'מעקבים תקופתיים', route: "/machines", isDisplay: userPersonalData.role === 'user' ? true : true},
        {icon: <AiOutlineTrophy size={24} />, title: 'הדרכות ותרגילים', route: "/trainings", isDisplay: userPersonalData.role === 'user' ? true : true},
        {icon: <AiOutlineSafety size={24} />, title: 'ועדות בטיחות', route: "/committees", isDisplay: userPersonalData.role === 'user' ? true : true},
        {icon: <MdCrisisAlert size={24} />, title: 'תחקיר תאונת עבודה', route: "/debriefing", isDisplay: userPersonalData.role === 'user' ? true : true},
      ]

  return (
    <div className="col-lg-2 d-flex flex-column bg-dark" style={{ minHeight: '100vh' }}>
    <div className='avatar-container'>
      {
        userPersonalData && <>
        <img src={userPersonalData.avatar} alt={userPersonalData.firstName} className='rounded mx-auto d-block' />
        <p className='profile-name'>{userPersonalData.firstName} {userPersonalData.lastName}</p>
        <p className='profile-email'>{userPersonalData.email}</p>
        </>
      }
    </div>
    

      {
        isAccountActive ? (<>

  <Link onClick={() => {setSelectedPage(home.title)}} to={home.route} className={selectedPage === home.title ? "sidemenu_link_active" : "sidemenu_link"}>{home.icon} <span style={{marginRight:12}}>{home.title}</span></Link>
      {
        pages.map((page,index) => (
            <>
            {
              page.isDisplay && (
                <>

                {
                  selectedCompany && selectedSite && <>
                    <Link 
                      onClick={() => {setSelectedPage(page.title)}} 
                      to={page.route} key={index} 
                      className={selectedPage === page.title ? "sidemenu_link_active" : "sidemenu_link"}>
                        {page.icon} <span style={{marginRight:12}}>{page.title}</span>
                    </Link>
                  </>
                }

                </>
              )
            }
            </>
        ))
      }

        {
          users.isDisplay && <Link 
          onClick={() => {setSelectedPage(users.title)}} 
          to={users.route}
          className={selectedPage === users.title ? "sidemenu_link_active" : "sidemenu_link"}>
            {users.icon} <span style={{marginRight:12}}>{users.title}</span>
          </Link>
        }

        <Link 
          onClick={() => {setSelectedPage(companies.title)}} 
          to={companies.route}
          className={selectedPage === companies.title ? "sidemenu_link_active" : "sidemenu_link"}>
            {companies.icon} <span style={{marginRight:12}}>{companies.title}</span>
        </Link>

        {
          settings.isDisplay && <Link 
          onClick={() => {setSelectedPage(settings.title)}} 
          to={settings.route}
          className={selectedPage === settings.title ? "sidemenu_link_active" : "sidemenu_link"}>
            {settings.icon} <span style={{marginRight:12}}>{settings.title}</span>
          </Link>
        }
        
        </>) : (<>
          {
          settings.isDisplay && <Link 
          onClick={() => {setSelectedPage(settings.title)}} 
          to={settings.route}
          className={selectedPage === settings.title ? "sidemenu_link_active" : "sidemenu_link"}>
            {settings.icon} <span style={{marginRight:12}}>{settings.title}</span>
          </Link>
          }

      {
        mainAccount.license === 'Free' && (
        <Link 
          to={"/planslist"}
          className="btn btn-warning">
            <MdCreditCard size={24} /> <span style={{marginRight:12}}>רכישת מנוי</span>
        </Link>

        )
      }
        </>)
      }

    

      
        




        {
          superAdmin.isDisplay && 
          <>
          <Link 
            onClick={() => {setSelectedPage(superAdmin.title)}} 
            to={superAdmin.route}
            className={selectedPage === superAdmin.title ? "sidemenu_link_active" : "sidemenu_link"}>
              {superAdmin.icon} <span style={{marginRight:12}}>{superAdmin.title}</span>
          </Link>

          <Link 
            onClick={() => {setSelectedPage(popups.title)}} 
            to={popups.route}
            className={selectedPage === popups.title ? "sidemenu_link_active" : "sidemenu_link"}>
              {popups.icon} <span style={{marginRight:12}}>{popups.title}</span>
          </Link>

          <Link 
            onClick={() => {setSelectedPage(plans.title)}} 
            to={plans.route}
            className={selectedPage === plans.title ? "sidemenu_link_active" : "sidemenu_link"}>
              {plans.icon} <span style={{marginRight:12}}>{plans.title}</span>
          </Link>

          <Link 
            onClick={() => {setSelectedPage(leads.title)}} 
            to={leads.route}
            className={selectedPage === leads.title ? "sidemenu_link_active" : "sidemenu_link"}>
              {leads.icon} <span style={{marginRight:12}}>{leads.title}</span>
          </Link>
          </>
        }

      {
        mainAccount.license === 'Free' && (
        <Link 
          to={"/planslist"}
          className="btn btn-warning">
            <MdCreditCard size={24} /> <span style={{marginRight:12}}>רכישת מנוי</span>
        </Link>

        )
      }


    </div>
  )
}

export default SideBarnav