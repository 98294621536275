import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { useParams } from "react-router-dom";
import useDashboardStore from "../../store/useDashboardStore";
import { FaRegFilePdf,FaEdit } from "react-icons/fa";

const DebriefingEdit = (props) => {
  const { id } = useParams();
  const {
    userPersonalData,
    selectedCompany,
    myCompany,
    companies,
    employees,
    periodics,
    addDebriefingReport,
    debriefingReports,
    updateMachineReport,
    removeDebriefingReportById,
    myPlan,
  } = useDashboardStore();

  const [report, setReport] = useState({});

  useEffect(() => {
    const selectedReport = debriefingReports.find(x => x.id === id);
    setReport(selectedReport)
  },[debriefingReports])

  const handleModal = async() => {}

  const [per1, setPer1] = useState("");
  const [per2, setPer2] = useState("");
  const [per3, setPer3] = useState("");

  useEffect(() => {
    if (report && employees.length > 0) {
      setPer1(
        report.recommendations_p1?.recommendations_p1_person
          ? employees.find((x) => x.id === report.recommendations_p1.recommendations_p1_person) || ""
          : ""
      );
      setPer2(
        report.recommendations_p2?.recommendations_p2_person
          ? employees.find((x) => x.id === report.recommendations_p2.recommendations_p2_person) || ""
          : ""
      );
      setPer3(
        report.recommendations_p3?.recommendations_p3_person
          ? employees.find((x) => x.id === report.recommendations_p3.recommendations_p3_person) || ""
          : ""
      );
    }
  }, [report, employees]);


  return (
    <>
    <div className="row heading-container">
        <div className="col-lg-10"><h3>תחקיר {report?.debriefType} | {report?.injuredEmployee?.firstName} {report?.injuredEmployee?.lastName}</h3></div>
        <div className="col-lg-2">
          {
            myPlan.actions && <>
            <button style={{marginRight:10}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            </>
          }
          <button style={{marginRight:10}} onClick={handleModal} className="btn btn-success"><FaEdit size={30} color="#ffffff" /></button>
        </div>
      </div>
      <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 14 }}>
        <div className="row">
          <div className="col-lg-4">
            <h5>פרטי העובד</h5>
            <br/>
            {report?.injuredEmployee?.avatar && (
                <img src={report?.injuredEmployee?.avatar} style={{ width: 180 }} />
              )}
            <p style={{marginTop:20}}>
              <b>שם העובד: </b>{report?.injuredEmployee?.firstName} {report?.injuredEmployee?.lastName}<br/>
              <b>מזהה מערכת: </b>{report?.injuredEmployee?.id}<br/>
              <b>תעודת זהות: </b>{report?.injuredEmployee?.employeeId}<br/>
              <b>מספר עובד: </b>{report?.injuredEmployee?.employeeNumber}<br/>
              <b>תפקיד: </b>{report?.injuredEmployee?.position}<br/>
              <b>טלפון: </b>{report?.injuredEmployee?.phone}<br/>
              <b>דואר אלקטרוני: </b>{report?.injuredEmployee?.email}<br/>
              <b>התווסף בתאריך: </b>{moment(report?.injuredEmployee?.createdAt).format("DD/MM/YYYY")}<br/>
              <b>כתובת: </b>{report?.injuredEmployee?.address}<br/>
              <b>קופת חולים: </b>{report?.injuredEmployee?.hospitality}<br/>
              <b>מחלקה: </b>{report?.injuredEmployee?.company}<br/>
            </p>
          </div>
          <div className="col-lg-4">
            <h5>פרטי האירוע</h5>
            <br/>
            <b>מזהה מערכת: </b>{report?.id}<br/>
            <b>סוג האירוע: </b>{report?.debriefType}<br/>
            <b>תאריך הוספת התחקיר: </b>{moment(report?.createdAt).format("DD/MM/YYYY")}<br/>
            <b>תאריך האירוע: </b>{moment(report?.reportDate).format("DD/MM/YYYY")}<br/>
            <b>שעה: </b>{report?.reportHour}<br/>
            <b>איבר שנפגע: </b>{report?.injuredLimb}<br/>
            <b>ימי היעדרות: </b>{report?.daysOfAbsence}<br/>
            <b>תיאור האירוע: </b>{report?.description}<br/>
            <b>דווח למשרד העבודה: </b>{report?.isReported}<br/>
            <b>קישור לדווח: </b><a href={report?.linkToReport} target="_blank">הקלק לפתיחת הקישור</a><br/>

            <b>עדים לאירוע: </b><br/>
            {
              report?.witnesses?.length > 0 ? (<>
              {
                report?.witnesses.map(item => (<>
                {item.firstName} {item.lastName}<br/>
                </>))
              }
              </>) : (<>
              לא היו עדים לאירוע
              </>)
            }
            <br/>


            {
              report?.extraWitnesses && (<><p><b>עדים נוספים: </b>{report.extraWitnesses}</p><br/></>)
            }



            <b>קבצים משוייכים: </b><br/>
            {
              report?.reportFiles?.length > 0 ? (<>
              {
                report?.reportFiles.map(item => (<>
                <a href={item} target="_blank">{item}</a><br/>
                </>))
              }
              </>) : (<>
              לא קיימים קבצים
              </>)
            }
            <br/>
            <b>מסקנות: </b><br/>{report?.conclusions}<br/>
            
            <br/><br/>
            <b>אדם: פרוט דברים שהאדם ביצע גרמו לתאונה, כולל שמות. ציוד מגן אישי, הדרכה, הסמכה, ותק: </b><br/>{report?.personDescription}<br/>
            <b>מכונה: פרוט מכונות או חלקי מכונה שהיו מעורבות בתאונה, האם המכונה נבדקה, פורק או היה חסר מיגון וכו': </b><br/>{report?.machineDescription}<br/>
            <b>משימה: איזו משימה ביצע העובד בעת התאונה, ואיזו היה צריך לבצע : </b><br/>{report?.taskDescription}<br/>
            <b>תנאי סביבה: ארגונומיה, סדר וניקיון, צפיפות, מפגעים וסיכונים, ועוד: </b><br/>{report?.envDescription}<br/>
            <b>נהלים: האם ישנם נהלים, הוראות עבודה, הדרכה, טפסים למילוי, עבודה בניגוד לנהלים: </b><br/>{report?.proceduresDescription}<br/>
          </div>
          <div className="col-lg-4">
            <h5>תפוצה והמשך טיפול</h5>
            <br/>


            {
              per1 && (<>
              <p>
                <b>{per1.firstName} {per1.lastName} - {per1.position}</b><br/>
                {report?.recommendations_p1?.recommendations_p1_desc}<br/>
                תאריך יעד לביצוע: {moment(report?.recommendations_p1?.recommendations_p1_date).format("DD/MM/YYYY")}
              </p><br/>
              </>)
            }

            {
              per2 && (<>
              <p>
                <b>{per2.firstName} {per2.lastName} - {per2.position}</b><br/>
                {report?.recommendations_p2?.recommendations_p2_desc}<br/>
                תאריך יעד לביצוע: {moment(report?.recommendations_p2?.recommendations_p2_date).format("DD/MM/YYYY")}
              </p><br/>
              </>)
            }

            {
              per3 && (<>
              <p>
                <b>{per3.firstName} {per3.lastName} - {per3.position}</b><br/>
                {report?.recommendations_p3?.recommendations_p3_desc}<br/>
                תאריך יעד לביצוע: {moment(report?.recommendations_p3?.recommendations_p3_date).format("DD/MM/YYYY")}
              </p><br/>
              </>)
            }
            <br/>
            <p><b>רשימת תפוצה</b></p>
            {
              report?.mailingList?.length > 0 && (<>
                {
                  report.mailingList.map(item => (
                    <><p>שם המקבל: {item.firstName} {item.lastName}, {item.position}</p></>
                  ))
                }
              </>)
            }


          </div>
        </div>
      </div>
    </>
  );
};

export default DebriefingEdit;
